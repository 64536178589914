import { h, render } from "preact"
import { useState } from "preact/hooks"

import { randomChoice, randomN, useInitialData } from "./utils.js"

const Terms = ({ klass }) => {
  const { terms, colors } = useInitialData("initial-terms")
  const [firstColor, secondColor] = randomN(colors, 2)

  const [term, setTerm] = useState(() => randomChoice(terms))

  return (
    <div class={`terms-wrapper ${klass || ""}`}>
      <a href={term.url} class="terms">
        <div class={`term first bg-${firstColor}`}>{term.first}</div>
        <div class={`term second bg-${secondColor}`}>{term.second}</div>
        {/* Just a label that looks like a button. That's why it's just a div */}
        <div class="button terms__explore-more">explore more</div>
      </a>
      <button
        class="terms__random"
        onClick={(e) => {
          e.preventDefault()
          setTerm(randomChoice(terms.filter((t) => t !== term)))
        }}
      >
        Show me another one
      </button>
    </div>
  )
}

export function renderTerms(el) {
  el && render(<Terms klass={el.dataset.terms} />, el)
}
