import { Fragment, h, render } from "preact"
import { useEffect, useMemo, useState, useRef } from "preact/hooks"
import throttle from "lodash/throttle"

import { Cards } from "./cards.js"
import { qs, useInitialData } from "./utils.js"

const SCROLL_THRESHOLD = 0

const Arrow = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
    <path
      d="M23.115 24.885 16.245 18l6.87-6.885L21 9l-9 9 9 9 2.115-2.115Z"
      fill="#000"
    />
    <circle cx="18" cy="18" r="17" stroke="#000" stroke-width="2" />
  </svg>
)

const useScrollable = (overflowRef) => {
  const [isScrollable, setScrollable] = useState({ left: false, right: false })

  useEffect(() => {
    const scrollableEl = overflowRef.current

    const scrollChecker = () => {
      if (!scrollableEl) return

      const { scrollWidth, offsetWidth, scrollLeft } = scrollableEl

      setScrollable({
        left: scrollLeft > SCROLL_THRESHOLD,
        right: scrollWidth > offsetWidth + scrollLeft + SCROLL_THRESHOLD,
      })
    }

    const scrollHandler = throttle(() => {
      scrollChecker()
    }, 16.667)

    scrollChecker()

    let resizeObserver = null

    if (scrollableEl) {
      scrollableEl.addEventListener("scroll", scrollHandler)
      resizeObserver = new ResizeObserver(() => {
        scrollChecker()
      })
      resizeObserver.observe(scrollableEl)
    }

    return () => {
      if (scrollableEl) {
        scrollableEl.removeEventListener("scroll", scrollHandler)
      }

      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [overflowRef, setScrollable])

  return isScrollable
}

const Locations = () => {
  const continentRefs = useRef({})
  const sliderRef = useRef(null)
  const isScrollable = useScrollable(sliderRef)
  const locations = useInitialData("locations")

  const locationsByContinents = useMemo(() => {
    return locations.reduce((acc, location) => {
      const { continentTitle, continent } = location

      const continentIndex = acc.findIndex(({ id }) => id === continent)

      if (continentIndex === -1) {
        acc.push({
          id: continent,
          title: continentTitle,
          locations: [location],
        })
      } else {
        acc[continentIndex].locations.push(location)
      }

      return acc
    }, [])
  }, [locations])

  useEffect(() => {
    const clickHandler = (e) => {
      const c = e.target.closest("g.continent")
      if (c === null) return

      sliderRef.current.scrollTo({
        left: continentRefs.current[c.id].offsetLeft,
        behavior: "smooth",
      })
    }
    qs("#world").addEventListener("click", clickHandler)

    return () => {
      qs("#world").removeEventListener("click", clickHandler)
    }
  }, [continentRefs, sliderRef])

  const scrollTo = (direction = 1) => {
    sliderRef.current.scrollBy({
      left: sliderRef.current.offsetWidth * 0.5 * direction,
      behavior: "smooth",
    })
  }

  return (
    <>
      <div class="card-slider-container">
        <ul class="card-slider" ref={sliderRef}>
          {locationsByContinents.map((c) => (
            <li
              key={c.id}
              class="card-slider__item"
              ref={(el) => (continentRefs.current[c.id] = el)}
            >
              <h2 className="continent-title">{c.title}</h2>
              <Cards projects={c.locations} />
            </li>
          ))}
        </ul>
      </div>
      <div class="card-slider-navigation">
        <button
          class="card-slider-navigation__button"
          disabled={!isScrollable.left}
          onClick={() => scrollTo(-1)}
        >
          <Arrow />
        </button>
        <button
          class="card-slider-navigation__button card-slider-navigation__button--flip"
          disabled={!isScrollable.right}
          onClick={() => scrollTo(1)}
        >
          <Arrow />
        </button>
      </div>
    </>
  )
}

export function renderLocations(el) {
  render(<Locations />, el)
}
