import { h } from "preact"

const Card = (props) => {
  return (
    <a
      href={props.url}
      class={[
        "card",
        props.isFeatured ? "is-featured" : "",
        props.isInverted ? "is-inverted" : "",
      ].join(" ")}
    >
      <div class="card-content">
        <div class="card-image">
          <div
            class="card-img"
            style={{ backgroundImage: `url("${props.image}")` }}
            aria-label={props.imageDescription}
          />
          {props.year ? <span class="card-year">{props.year}</span> : null}
        </div>
        <div class="card-body">
          <h2 class="card-title">{props.title}</h2>
        </div>
      </div>
    </a>
  )
}

export const Cards = ({ projects }) => (
  <div class="cards">
    {projects.map((project) => (
      <Card key={project.id} {...project} />
    ))}
  </div>
)
