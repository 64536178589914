// import "preact/debug"
import "./main.scss"

// Only Safari 15.4 or better (released in March 2022) support this...
import smoothscroll from "smoothscroll-polyfill"
smoothscroll.polyfill()

import { onReady, qs, qsa } from "./utils.js"

import { renderLocations } from "./locations.js"
import { renderProjects, renderProjectsTeaser } from "./projects.js"
import { renderTerms } from "./terms.js"
import { renderPartners } from "./partners.js"
import { renderRegions } from "./regions.js"

const initMenuObserver = () => {
  const io = window.IntersectionObserver
  if (!io) return

  const callback = (entries) => {
    entries.forEach((entry) =>
      entry.target.classList.toggle("is-off", !entry.isIntersecting),
    )
  }

  new io(callback).observe(qs(".top"))
}

const initSharingPopups = () => {
  qsa(".sharing-button").forEach((el) =>
    el.addEventListener("click", (e) => {
      e.preventDefault()
      const win = window.open(
        e.target.href,
        "sharing",
        "height=600,width=800,resizable=yes,scrollbars=yes",
      )
      win.focus()
    }),
  )
}

function initialize() {
  qsa("[data-terms]").forEach(renderTerms)
  qsa("[data-topics]").forEach(renderProjects)
  qsa("[data-locations]").forEach(renderLocations)
  qsa("[data-projects]").forEach(renderProjectsTeaser)
  qsa("[data-partners]").forEach(renderPartners)
  qsa(".is-highlighted p").forEach((p) => {
    p.innerHTML = `<span>${p.innerHTML}</span>`
  })
  initSharingPopups()
}

onReady(() => {
  initialize()
  initMenuObserver() // Only once.

  qsa("#regions-data").forEach((el) => {
    renderRegions(el, initialize)
  })
})
