// http://youmightnotneedjquery.com/

import { useMemo } from "preact/hooks"
import shuffle from "@anttikissa/fisher-yates-shuffle"

export function onReady(fn) {
  if (document.readyState != "loading") {
    fn()
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

export function qs(sel, doc = document) {
  return doc.querySelector(sel)
}

export function qsa(sel, doc = document) {
  return Array.prototype.slice.call(doc.querySelectorAll(sel))
}

export const useInitialData = (id) =>
  useMemo(() => {
    const el = document.getElementById(id)
    return JSON.parse(el.textContent)
  }, [id])

export const randomChoice = (arr) => arr[Math.floor(Math.random() * arr.length)]
export const randomN = (arr, n) => shuffle(arr).slice(0, n)
